import { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { subscribeEmployeeByEmail } from "../../api/email";
import logo from "../../../public/text-logo.svg";
import theme from "../../theme";
import { Trans, useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import { EmailTemplateTypeEnum } from "../../models/api/resource";

export default function Resubscribe() {
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(Translation.email);
  const [searchParams] = useSearchParams();
  const orgName = searchParams.get("orgName") || "";
  const orgId = searchParams.get("orgId") || "";
  const emailType = searchParams.get("emailType") || "";

  useEffect(() => {
    if (orgId && employeeId) {
      subscribeEmployeeByEmail(orgId, employeeId)
        .then(() => {})
        .catch(() => {});
    }
  }, [orgId, employeeId]);

  const handleResubscribeClick = () => {
    navigate(
      `/unsubscribe/${employeeId}?orgName=${encodeURIComponent(
        orgName,
      )}&orgId=${orgId}&emailType=${emailType}`,
    );
  };

  const getResubscribeTitle = () => {
    if (emailType === EmailTemplateTypeEnum.SEND_ROLE) {
      return t("resubscribe.sendRole.title");
    } else if (emailType === EmailTemplateTypeEnum.REFERRAL) {
      return t("resubscribe.referrals.title");
    } else {
      return t("resubscribe.title");
    }
  };

  const getResubscribeMessageKey = () => {
    if (emailType === EmailTemplateTypeEnum.SEND_ROLE) {
      return "resubscribe.sendRole.message";
    } else if (emailType === EmailTemplateTypeEnum.REFERRAL) {
      return "resubscribe.referrals.message";
    } else {
      return "resubscribe.message";
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: theme.palette.custom.greyBackground,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Stack
        spacing={2}
        sx={{
          maxWidth: 800,
          width: "100%",
          padding: "2rem",
          backgroundColor: theme.palette.common.white,
          borderRadius: "20px",
          boxSizing: "border-box",
          textAlign: "center",
          border: `1px solid ${theme.palette.divider}`,
          mb: "64px",
        }}
      >
        <Typography variant="h5" component="h1" fontWeight={600}>
          {getResubscribeTitle()}
        </Typography>
        <Typography
          fontSize="15px"
          gutterBottom
          sx={{
            color: theme.palette.grey[500],
          }}
        >
          <Trans
            ns="email"
            i18nKey={getResubscribeMessageKey()}
            values={{ orgName }}
            components={{
              1: (
                <span
                  style={{
                    cursor: "pointer",
                    color: theme.palette.primary.main,
                  }}
                  onClick={handleResubscribeClick}
                />
              ),
            }}
          />
        </Typography>
      </Stack>
      <Box
        sx={{
          position: "absolute",
          bottom: 100,
          left: 0,
          right: 0,
          height: "1px",
          backgroundColor: theme.palette.divider,
          maxWidth: "90%",
          margin: "0 auto",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "90px",
          pb: "40px",
          pt: "25px",
        }}
      >
        <a
          href="https://www.hiboomerang.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box
            component="img"
            src={logo}
            alt="Boomerang Logo"
            sx={{ height: 30 }}
          />
        </a>
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.grey[500],
          }}
        >
          {t("copyright")}
        </Typography>
      </Box>
    </Box>
  );
}
