import { createTheme } from "@mui/material";

type NotificationType = "success" | "error" | "info";

type NotificationColors = {
  color: string;
  backgroundColor: string;
  borderColor: string;
};
declare module "@mui/material/styles" {
  interface Palette {
    custom: Record<string, string>;
    attribute: Record<string, Record<string, string>>;
    primaryColor: Record<number, string>;
    errorColor: Record<number, string>;
    notification: Record<NotificationType, NotificationColors>;
    tag: Record<string, Record<string, string>>;
    blueLight: Record<number, string>;
  }
  interface PaletteOptions {
    custom: Record<string, string>;
    attribute: Record<string, Record<string, string>>;
    primaryColor: Record<number, string>;
    errorColor: Record<number, string>;
    notification: Record<NotificationType, NotificationColors>;
    tag: Record<string, Record<string, string>>;
    blueLight: Record<number, string>;
  }
}

export default createTheme({
  palette: {
    primary: {
      main: "#7F3AF2",
      dark: "#7F56D9",
      light: "#F9F5FF",
    },
    primaryColor: {
      25: "#FCFAFF",
      50: "#F9F5FF",
      100: "#F2EBFE",
      200: "#E0D0FC",
      300: "#C8AAF9",
      400: "#AE83F7",
      500: "#965DF4",
      600: "#7F3AF2",
      700: "#7234DA",
      800: "#662EC2",
    },
    grey: {
      50: "#F9FAFB",
      100: "#F2F4F7",
      200: "#EAECF0",
      300: "#D0D5DD",
      400: "#98A2B3",
      500: "#667085",
      600: "#475467",
      700: "#344054",
      800: "#1D2939",
    },
    blueLight: {
      50: "#F0F9FF",
      200: "#B9E6FE",
      700: "#026AA2",
    },
    errorColor: {
      25: "#FFFBFA",
      50: "#FFF6F8",
      100: "#FEE1E3",
      200: "#FEC8CD",
      300: "#FCA6AD",
      400: "#F8727D",
      500: "#EF4352",
      600: "#E02D3C",
      700: "#BA2532",
      800: "#981B25",
    },
    notification: {
      success: {
        color: "#067647",
        backgroundColor: "#F6FEF9",
        borderColor: "#75E0A7",
      },
      error: {
        color: "#B42318",
        backgroundColor: "#FFFBFA",
        borderColor: "#FCA6AD",
      },
      info: {
        color: "#475467",
        backgroundColor: "#FCFCFD",
        borderColor: "#D0D5DD",
      },
    },
    divider: "#EAECF0",
    text: {
      primary: "#1D2939",
      secondary: "#475467",
    },
    background: {
      default: "#FFFFFF",
    },
    success: {
      main: "#17B26A",
      dark: "#079455",
    },
    error: {
      main: "#D92D20",
      dark: "#B42318",
      light: "#FFFBFA",
    },
    custom: {
      navGrey: "#F3F4F7",
      purpleBorder: "#E9D7FE",
      purpleDisabled: "#E0D0FC",
      purpleHover: "#7234DA",
      purplePressed: "#662EC2",
      warningBackground: "#FFFCF5",
      warningBorder: "#FEDF89",
      warningText: "#B54708",
      errorBorder: "#FFA7A0",
      greyBackground: "#FCFCFD",
      automatedColor: "#067647",
      automatedBorder: "#ABEFC6",
      successBackground: "#ECFDF3",
      manualColor: "#3538CD",
      manualBorder: "#C7D7FE",
      manualBackground: "#EEF4FF",
    },
    attribute: {
      promotion: {
        text: "#4F7A21",
        background: "#F5FBEE",
      },
      salary: {
        text: "#0E9384",
        background: "#F0FDF9",
      },
      tenure: {
        text: "#026AA2",
        background: "#F0F9FF",
      },
      location: {
        text: "#9939AC",
        background: "#FDF4FF",
      },
    },
    tag: {
      manual: {
        text: "#3538CD",
        border: "#C7D7FE",
        background: "#EEF4FF",
      },
      automated: {
        text: "#067647",
        border: "#ABEFC6",
        background: "#ECFDF3",
      },
    },
  },
  typography: {
    fontFamily: `'Inter', sans-serif`,
    button: {
      fontSize: "1rem",
      fontWeight: 600,
      textTransform: "none",
    },
  },
});
