import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { getCsrfToken } from "./api/auth";
import ContextProvider from "./components/ContextProvider";
import Loading from "./components/Loading";
import "./i18n";
import { router } from "./route";
import "./styles.global.css";
import theme from "./theme";
import { GA_MEASUREMENT_ID } from "./env";
import ReactGA from "react-ga4";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/md-light-indigo/theme.css";


getCsrfToken();
if (GA_MEASUREMENT_ID) {
  ReactGA.initialize(GA_MEASUREMENT_ID);
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ContextProvider>
          <PrimeReactProvider >
            <RouterProvider router={router} />
          </PrimeReactProvider>
        </ContextProvider>
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>,
);
