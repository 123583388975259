import { Stack, Typography } from "@mui/material";
import theme from "../../theme";
import {
  CompanyContactDTO,
  RelationshipTypeEnum,
} from "../../models/api/alum.ts";
import { formatTimeSinceCrossover } from "../../lib/time.ts";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants.ts";
import ProfilePhoto from "../ProfilePhoto";

interface CompanyContactProps {
  contact: CompanyContactDTO;
}

export default function CompanyContactsCard({ contact }: CompanyContactProps) {
  const { t } = useTranslation(Translation.alumProfile);

  return (
    <Stack direction="row" gap="1rem">
      <Stack>
        <ProfilePhoto
          photoUrl={contact.profile_photo_url}
          firstName={contact.first_name ?? ""}
          lastName={contact.last_name ?? ""}
          sx={{ flexShrink: 0, flexGrow: 0 }}
        />
      </Stack>
      <Stack gap="0.75rem">
        <Stack gap="0.1rem">
          <Typography fontWeight={600} fontSize="0.875rem">
            {contact.first_name + " " + contact.last_name}
          </Typography>
          <Typography fontWeight={400} fontSize="0.875rem">
            {contact.current_role}
          </Typography>
          <Typography
            fontWeight={400}
            fontSize="0.875rem"
            color={theme.palette.grey[500]}
          >
            {contact.email}
          </Typography>
        </Stack>
        <Stack gap="0.1rem">
          <Typography fontWeight={500} fontSize="0.875rem">
            {t("companyContacts.formerRelationship")}
          </Typography>
          <Typography fontWeight={400} fontSize="0.875rem">
            {contact.role_during_crossover}
          </Typography>
          <Stack
            sx={{
              display: "flex",
              padding: "0.125rem 0.375rem",
              alignItems: "flex-start",
              borderRadius: "0.375rem",
              border:
                contact.relationship_type === RelationshipTypeEnum.DIRECT_REPORT
                  ? "1px solid " + theme.palette.grey[300]
                  : "1px solid " + theme.palette.blueLight[200],
              background:
                contact.relationship_type === RelationshipTypeEnum.DIRECT_REPORT
                  ? theme.palette.background.default
                  : theme.palette.blueLight[50],
              width: "max-content",
            }}
          >
            <Typography
              fontWeight={400}
              fontSize="0.75rem"
              color={
                contact.relationship_type === RelationshipTypeEnum.DIRECT_REPORT
                  ? theme.palette.grey[800]
                  : theme.palette.blueLight[700]
              }
            >
              {contact.relationship_type === RelationshipTypeEnum.MANAGER
                ? t("companyContacts.manager")
                : t("companyContacts.directReport")}
              {contact.time_since_crossover >= 60 * 60 * 24 * 365
                ? // we use months if time since crossover is less than a year
                  t("companyContacts.contactSinceYears", {
                    years: formatTimeSinceCrossover(
                      contact.time_since_crossover,
                    ).year,
                  })
                : t("companyContacts.contactSinceMonths", {
                    months: formatTimeSinceCrossover(
                      contact.time_since_crossover,
                    ).month,
                  })}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
