import { useTranslation } from "react-i18next";
import theme from "../../theme";
import {
  JobMatchStatus,
  getJobMatchStatusString,
} from "../../models/job";
import briefCaseIcon from "/briefcase-light.svg";
import locationIcon from "/location-marker.svg";
import userIcon from "/user.svg";
import calendarIcon from "/calendar.svg";
import { Translation } from "../../lib/constants";
import SubtitleWithIcon from "../../theme/SubtitleWithIcon";
import { Format } from "../../lib/utils";
import { FormattedText } from "../../theme/FormattedText";
import { FlagsContext } from "../../lib/context";
import { Box, Stack, Typography, Button } from "@mui/material";
import { useContext } from "react";

interface RoleCardProps {
  title: string;
  department: string;
  locations: string[];
  recruiters: string[];
  datePosted: string | null;
  status: JobMatchStatus;
  statusModifiedDate: Date | null;
  emailsSent: number;
  requisitionId: string;
  onSendRole: () => void;
  onClick: () => void;
}

export default function RoleCard({
  title,
  department,
  locations,
  recruiters,
  datePosted,
  status,
  statusModifiedDate,
  emailsSent,
  requisitionId,
  onSendRole,
  onClick,
}: RoleCardProps) {
  const { t, i18n } = useTranslation(Translation.alumProfile);
  const [flags] = useContext(FlagsContext);

  const handleSendRoleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onSendRole();
  };

  const isAlumniProfileV2 = flags?.includes("ALUMNI_PROFILE_V2");

  return (
    <Stack
      className="role-card"
      width={isAlumniProfileV2 ? undefined : "22rem"}
      borderRadius="0.5rem"
      border={
        isAlumniProfileV2 ? undefined : `1px solid ${theme.palette.divider}`
      }
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      <Stack
        padding={isAlumniProfileV2 ? undefined : "1.5rem"}
        pb={isAlumniProfileV2 ? "1.5rem" : undefined}
        gap="1.5rem"
        borderBottom={`1px solid ${theme.palette.divider}`}
      >
        <Stack gap="0.5rem">
          <Box
            fontSize="0.875rem"
            lineHeight="1.25rem"
            pb={isAlumniProfileV2 ? undefined : "1rem"}
          >
            <FormattedText
              props={{
                value: title,
                format: Format.uppercaseFirstLetter,
                ellipsis: false,
              }}
              style={{
                fontWeight: 600,
                lineHeight: "1.5rem",
                fontSize: isAlumniProfileV2 ? "0.875rem" : "1rem",
                color: isAlumniProfileV2
                  ? theme.palette.primary.main
                  : undefined,
              }}
            />
            <Typography fontWeight={400} color={theme.palette.grey[500]}>
              {requisitionId}
            </Typography>
          </Box>
          {department && (
            <SubtitleWithIcon
              subtitle={Format.uppercaseFirstLetter(department)}
              icon={briefCaseIcon}
              iconAlt={t("iconAlt")}
            />
          )}
          {locations.length > 0 && (
            <SubtitleWithIcon
              subtitle={Format.location(locations.join(" • "))}
              icon={locationIcon}
              iconAlt={t("iconAlt")}
            />
          )}
          {recruiters.length > 0 && (
            <SubtitleWithIcon
              subtitle={Format.uppercaseFirstLetter(recruiters.join(" • "))}
              icon={userIcon}
              iconAlt={t("iconAlt")}
            />
          )}
          {datePosted && (
            <SubtitleWithIcon
              subtitle={Format.dateDayMonthYear(datePosted)}
              icon={calendarIcon}
              iconAlt={t("iconAlt")}
            />
          )}
        </Stack>
      </Stack>
      <Stack
        padding={isAlumniProfileV2 ? undefined : "1.5rem"}
        py={isAlumniProfileV2 ? "1.5rem" : undefined}
        gap="0.5rem"
        borderBottom={`1px solid ${theme.palette.divider}`}
      >
        <Stack direction="row" gap="0.5rem" justifyContent="space-between">
          <Typography fontSize="0.75rem" lineHeight="1.125rem">
            {t("roleMatches.status")}
          </Typography>
          <Stack alignItems="flex-end">
            <Typography
              fontSize="0.75rem"
              lineHeight="1.125rem"
              color={theme.palette.grey[500]}
            >
              {getJobMatchStatusString(status, t)}
            </Typography>
            <Typography
              fontSize="0.75rem"
              lineHeight="1.125rem"
              color={theme.palette.grey[500]}
            >
              {statusModifiedDate?.toLocaleDateString(i18n.language, {
                month: "long",
                day: "numeric",
                year: "numeric",
              }) ?? ""}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" gap="0.5rem" justifyContent="space-between">
          <Typography fontSize="0.75rem" lineHeight="1.125rem">
            {t("roleMatches.emailsSent")}
          </Typography>
          <Typography
            fontSize="0.75rem"
            lineHeight="1.125rem"
            color={theme.palette.grey[500]}
          >
            {emailsSent}
          </Typography>
        </Stack>
      </Stack>
      <Box
        padding={isAlumniProfileV2 ? undefined : "1.5rem"}
        pt={isAlumniProfileV2 ? "1.5rem" : undefined}
      >
        <Button
          className="send-role-button"
          fullWidth
          disableRipple
          sx={{
            padding: "0.625rem 1rem",
            fontFamily: "inherit",
            fontSize: "0.875rem",
            fontWeight: 600,
            lineHeight: "1.25rem",
            background: theme.palette.common.white,
            cursor: "pointer",
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: "0.5rem",
            color: theme.palette.grey[600],
            "&:hover": {
              background: theme.palette.grey[50],
            },
            "&:active": {
              color: theme.palette.grey[800],
            },
          }}
          onClick={handleSendRoleClick}
        >
          {t("roleMatches.sendRole")}
        </Button>
      </Box>
    </Stack>
  );
}
