import { useTranslation } from "react-i18next";
import {
  ChangeEvent,
  SyntheticEvent,
  useState,
  useEffect,
  useContext,
} from "react";
import { Box, Typography, Stack, Tab, Tabs } from "@mui/material";
import HelpCircleIcon from "/help-circle-secondary.svg";
import theme from "../../theme";
import Tooltip from "../../theme/Tooltip";
import Input from "../../theme/Input";
import MultiSelectComboBox, {
  MultiSelectOption,
} from "../../components/MultiSelectComboBox";
import { getEmailTemplate } from "../../api/resource";
import { Translation } from "../../lib/constants";
import {
  sendApprovalEmail,
  renderApprovalEmail,
  ApprovalEmailContent,
} from "../../api/email";
import parse from "html-react-parser";
import { UserContext } from "../../lib/context";
import Loading from "../../components/Loading";
import Switch from "../../theme/Switch";
import { JobMatchStatus } from "../../models/job";
import ErrorToastContainer from "../../components/Toastify/ErrorToastContainer";
import { toast } from "react-toastify";
import ModalTemplate from "./ModalTemplate";
import { EmailTemplateTypeEnum } from "../../models/api/resource";
import { hasEmptyStringValues, validateEmail } from "../../lib/utils";
import { JobDashboardDTO } from "../../models/api/job";
import AutocompleteEmailInput, {
  EmailRecipient,
} from "../ActiveEmployeeAutoComplete";
import { searchActiveEmployees } from "../../api/alum.ts";
import { AutocompleteEmployeeDTO } from "../../models/api/alum.ts";

export interface Receiver {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status?: JobMatchStatus;
}

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  receivers: Receiver[];
  selectedIds: Set<string>;
  job: JobDashboardDTO;
}

export default function RequestFeedbackModal({
  onSubmit,
  onClose,
  receivers,
  selectedIds,
  job,
}: Props) {
  const { t } = useTranslation(Translation.sendEmailModal);
  const [tab, setTab] = useState(0);
  const [user] = useContext(UserContext);
  const orgId = user?.orgId ?? "";
  const [emailRecipients, setEmailRecipients] = useState<EmailRecipient[]>([]);
  const [emailPreview, setEmailPreview] = useState("");
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [emailContent, setEmailContent] = useState<ApprovalEmailContent>({
    body: "",
    subject: "",
    emails: "",
    includeSignature: false,
  });
  const [defaultContent, setDefaultContent] = useState<{
    subject: string;
    body: string;
  }>({
    body: "",
    subject: "",
  });
  const [error, setError] = useState({
    subject: "",
    body: "",
    emails: "",
  });

  const translateReceiverToMultiSelectOption = (receiver: Receiver) => {
    const hasEmail = receiver.email.length > 0;
    const isHired = receiver.status === JobMatchStatus.HIRED;

    let label = `${receiver.firstName} ${receiver.lastName}`;
    if (isHired) {
      label += ` ${t("hired")}`;
    }

    return {
      value: receiver.id,
      label: label,
      selectable: !isHired,
      dropdownLabelDetails: hasEmail ? receiver.email : undefined,
    };
  };

  const [multiSelectOptions] = useState<MultiSelectOption[]>(
    receivers.map(translateReceiverToMultiSelectOption),
  );
  const [newSelectedIds, setNewSelectedIds] = useState<Set<string>>(
    new Set(
      receivers
        .filter((receiver) => selectedIds.has(receiver.id))
        .map((receiver) => receiver.id),
    ),
  );

  const submitApprovalRequest = async () => {
    if (
      newSelectedIds.size === 0 ||
      hasEmptyStringValues(emailContent) ||
      error.emails !== ""
    ) {
      if (emailRecipients.length === 0) {
        setError((prev) => ({
          ...prev,
          emails: t("error.emails"),
        }));
      }

      toast.error(t("toast.emptyFields"), {
        containerId: "request-feedback-error",
        toastId: "empty-fields",
      });
      return;
    }

    try {
      await sendApprovalEmail(
        orgId,
        emailContent,
        job.id,
        Array.from(newSelectedIds),
      );
      onSubmit();
    } catch (error) {
      toast.error(t("toast.sendError"), {
        containerId: "request-feedback-error",
        toastId: "send-error",
      });
    }
  };

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let errorText = value.length == 0 ? t(`error.${name}`) : "";

    if (name === "emails" && value.length > 0) {
      const allEmailsValid = value
        .split(",")
        .map((it) => it.trim())
        .every(validateEmail);
      if (!allEmailsValid) {
        errorText = t("error.emailFormat");
      }
    }
    setError({
      ...error,
      [name]: errorText,
    });
    setEmailContent({
      ...emailContent,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchEmailTemplate = async () => {
      setLoadingDraft(true);
      try {
        const response = await getEmailTemplate(
          orgId,
          EmailTemplateTypeEnum.JOB_MATCH_APPROVAL,
        );
        setDefaultContent({
          body: response.body,
          subject: response.subject,
        });
      } catch (error) {
        setDefaultContent({
          body: t("backupContent.requestFeedback.body"),
          subject: t("backupContent.requestFeedback.subject"),
        });
      } finally {
        setLoadingDraft(false);
      }
    };

    void fetchEmailTemplate();
  }, [orgId, t]);

  useEffect(() => {
    const fetchEmailPreview = async () => {
      setLoadingPreview(true);
      try {
        const response = await renderApprovalEmail(
          orgId,
          emailContent,
          job.id,
          Array.from(newSelectedIds),
        );
        setEmailPreview(response);
      } catch (error) {
        // TODO: BOO-1036
      } finally {
        setLoadingPreview(false);
      }
    };

    if (tab == 1) {
      void fetchEmailPreview();
    }
  }, [emailContent, job.id, newSelectedIds, orgId, tab]);

  useEffect(() => {
    setEmailContent({
      body: defaultContent.body,
      subject: defaultContent.subject,
      emails: "",
      includeSignature: true,
    });
  }, [defaultContent]);

  return (
    <>
      <ErrorToastContainer
        containerId="request-feedback-error"
        style={{ width: "652px" }}
      />
      <ModalTemplate
        heading={t("heading.approval")}
        onClose={() => onClose()}
        primaryButtonOnClick={() => void submitApprovalRequest()}
        primaryButtonText={t("send")}
        subheading={t("subHeading.approval")}
      >
        <>
          <Stack
            direction="column"
            justifyContent="space-between"
            padding="0rem 1.5rem"
          >
            <Box
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
              })}
            >
              <Tabs
                textColor="inherit"
                value={tab}
                onChange={(_event: SyntheticEvent, newValue: number) =>
                  setTab(newValue)
                }
                TabIndicatorProps={{
                  style: {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
              >
                <Tab
                  id="send-email-draft"
                  disableRipple
                  sx={{
                    minWidth: "2.5rem",
                    maxWidth: "2.5rem",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                  }}
                  label={t("draft")}
                />
                <Tab
                  id="send-email-preview"
                  disableRipple
                  sx={{
                    marginLeft: "1rem",
                    minWidth: "3.5rem",
                    maxWidth: "3.5rem",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                  }}
                  label={t("preview")}
                />
              </Tabs>
            </Box>
          </Stack>
          <Box
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.grey[200]}`,
              overflow: "auto",
            })}
          >
            {tab == 0 && (
              <Stack
                id="feedback-email-form"
                direction="column"
                padding="1.5rem 2rem 2rem 2rem"
              >
                {loadingDraft ? (
                  <Loading />
                ) : (
                  <>
                    <div>
                      <AutocompleteEmailInput
                        value={emailRecipients}
                        setError={(errorText) =>
                          setError((prev) => ({
                            ...prev,
                            emails: errorText,
                          }))
                        }
                        onChange={(newValue) => {
                          setEmailRecipients(newValue);
                          setEmailContent({
                            ...emailContent,
                            emails: newValue
                              .map((recipient) => recipient.email)
                              .join(", "),
                          });
                          // Clear any previous errors if we have valid recipients
                          if (newValue.length > 0) {
                            setError((prev) => ({
                              ...prev,
                              emails: "",
                            }));
                          }
                        }}
                        onInputChange={async (searchTerm) => {
                          try {
                            const response = await searchActiveEmployees(
                              orgId,
                              searchTerm,
                              10,
                            );
                            return response.map(
                              (
                                employee: AutocompleteEmployeeDTO,
                              ): EmailRecipient => ({
                                firstName: employee.name.first_name,
                                lastName: employee.name.last_name,
                                email: employee.email,
                                isManualEntry: false,
                              }),
                            );
                          } catch (error) {
                            console.error("Error fetching employees:", error);
                            return [];
                          }
                        }}
                        error={error.emails}
                      />
                    </div>

                    <ModalInputLabel
                      labelText={t("matchesTooltip.label")}
                      tooltipContent={t("matchesTooltip.content")}
                    />

                    <MultiSelectComboBox
                      options={multiSelectOptions}
                      selectedValues={newSelectedIds}
                      setSelectedValues={setNewSelectedIds}
                      selectAllLabel={t("selectAll")}
                      inputErrorMessage={t("error.recipients")}
                      showChevron={true}
                      readOnly={false}
                      matchFromStartFilter
                    />

                    <Input
                      id="subject-section"
                      label={t("subject")}
                      placeholder=""
                      required={true}
                      error={error.subject}
                      onChange={handleFormChange}
                      stackProps={{
                        paddingTop: "1.125rem",
                      }}
                      inputProps={{
                        id: "subject-section-text",
                        name: "subject",
                        value: emailContent.subject,
                        sx: {
                          borderRadius: "0.5rem",
                          "& .MuiOutlinedInput-input": {
                            fontSize: "0.875rem",
                            padding: "10px 14px",
                            lineHeight: "1.5rem",
                            fontWeight: "400",
                            color: "grey.800",
                          },
                          fieldset: {
                            borderColor:
                              theme.palette.grey[300] + " !important",
                          },
                          "&.Mui-focused": {
                            fieldset: {
                              borderColor:
                                theme.palette.primary.dark + " !important",
                            },
                          },
                          "&.Mui-error": {
                            fieldset: {
                              borderColor:
                                theme.palette.custom.errorBorder +
                                " !important",
                            },
                          },
                        },
                      }}
                    />

                    <Input
                      id="body-section"
                      label={t("body")}
                      required={true}
                      placeholder=""
                      onChange={handleFormChange}
                      error={error.body}
                      stackProps={{ paddingTop: "1.125rem" }}
                      inputProps={{
                        id: "body-section-text",
                        name: "body",
                        multiline: true,
                        value: emailContent.body,
                        rows: 10.75,
                        sx: {
                          borderRadius: "0.5rem",
                          "& .MuiOutlinedInput-input": {
                            fontSize: "0.875rem",
                            lineHeight: "1.5rem",
                            fontWeight: "400",
                            color: "grey.800",
                          },
                          fieldset: {
                            borderColor:
                              theme.palette.grey[300] + " !important",
                          },
                          "&.Mui-focused": {
                            fieldset: {
                              borderColor:
                                theme.palette.primary.dark + " !important",
                            },
                          },
                          "&.Mui-error": {
                            fieldset: {
                              borderColor:
                                theme.palette.custom.errorBorder +
                                " !important",
                            },
                          },
                        },
                      }}
                    />

                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      paddingTop="1.125rem"
                    >
                      <Switch
                        className="include-sig-switch"
                        switchsize="large"
                        checked={emailContent.includeSignature}
                        onChange={() =>
                          setEmailContent({
                            ...emailContent,
                            includeSignature: !emailContent.includeSignature,
                          })
                        }
                      />
                      <Typography
                        fontSize="0.875rem"
                        fontWeight={600}
                        lineHeight="1.25rem"
                        paddingLeft="12px"
                      >
                        {t("includeSignature")}
                      </Typography>
                    </Stack>
                  </>
                )}
              </Stack>
            )}
            {tab == 1 && (
              <Stack
                id="email-preview"
                sx={{
                  minHeight: "500px",
                  padding: "0px 2px",
                  "& .approval-email-body": {
                    maxWidth: "800px !important",
                  },
                  "& .approval-card": {
                    width: "276px !important",
                  },
                  alignItems: "center",
                }}
              >
                {loadingPreview ? <Loading /> : parse(emailPreview)}
              </Stack>
            )}
          </Box>
        </>
      </ModalTemplate>
    </>
  );
}

const ModalInputLabel = ({
  labelText,
  tooltipContent,
}: {
  labelText: string;
  tooltipContent: string;
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      paddingBottom="0.375rem"
      paddingTop="1.125rem"
    >
      <Stack direction="row" alignItems="center" className="to-section">
        <Typography
          fontSize="0.875rem"
          fontWeight={500}
          color={theme.palette.grey[800]}
          lineHeight="1.25rem"
        >
          {labelText}
        </Typography>

        <Tooltip
          arrow={false}
          title={tooltipContent}
          className="tooltip"
          componentsProps={{
            tooltip: {
              sx: {
                padding: "0.75rem",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                borderRadius: "0.5rem",
                bgcolor: "white",
                color: "grey.500",
                fontSize: "0.75rem",
                fontWeight: "500",
                lineHeight: "1.125rem",
                boxShadow: "0px 0.5rem 0.5rem #00000034",
              },
            },
          }}
        >
          <Box component="img" src={HelpCircleIcon} paddingLeft="0.25rem" />
        </Tooltip>
      </Stack>
    </Stack>
  );
};
