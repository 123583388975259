import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, Stack, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import ApprovalCard from "./ApprovalCard";
import textLogo from "/text-logo.svg";
import { approveMatch, rejectMatch } from "../../api/alum.ts";
import Loading from "../../components/Loading/index.tsx";
import alertCircleIcon from "/alert-circle.svg";
import theme from "../../theme/index.ts";

const ApprovalPage = ({ type }: { type: "approved" | "rejected" }) => {
  const { t } = useTranslation(Translation.common);
  const { approvalCode } = useParams();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  if (!approvalCode) {
    return (<></>);
  }

  const handleApproval = async () => {
    let response;
    try {
      if (type === "approved") {
        response = await approveMatch(approvalCode);
      } else if (type === "rejected") {
        response = await rejectMatch(approvalCode);
      }
      if (response && response.first_name !== "" && response.last_name !== "") {
        setName(response.first_name + " " + response.last_name);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleApproval();
  }, [type, approvalCode]);

  return (
    <Stack height="100vh" width="100vw" padding="48px 80px">
      <Stack flex="1" justifyContent="center" alignItems="center">
        { loading ? 
        (<Loading text={t("loading")}/>) : 
        (
          error ? 
          (<ErrorCard />) : 
          (<ApprovalCard type={type} name={name}/>)
        )}
      </Stack>

      <Stack
        width="100%"
        borderTop="1px solid var(--Gray-200, #EAECF0)"
        paddingTop="32px"
        justifyContent="space-between"
        direction="row"
      >
        <Box
          component="img"
          sx={{ width: "9rem" }}
          alt={t("footer.boomerangLogoAlt")}
          src={textLogo}
        />

        <Typography
          color="var(--Gray-500, #667085)"
          fontFamily="Inter"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="24px"
        >
          {t("footer.rights")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ApprovalPage;

const ErrorCard = () => {
  const { t } = useTranslation(Translation.common);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      gap="8px"
      sx={{
        width: "452px",
        padding: "32px",
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '12px', borderRadius: '50%', backgroundColor: theme.palette.errorColor[50] }}>
      <img
      height="28px"
      width="28px"
        src={alertCircleIcon}
        alt={t("alertIconAlt", {ns: Translation.common})}
      />
      </div>

      <Typography
        lineHeight="28px"
        fontSize="18px"
        fontWeight={600}
        component="div"
        gutterBottom
        sx={(theme) => ({ color: theme.palette.grey[800] })}
      >
        {t("matchApproval.error.heading")}
      </Typography>

      <Typography
      lineHeight="20px"
      fontSize="14px"
      fontWeight={400}
      textAlign={"center"}
      sx={(theme) => ({ color: theme.palette.grey[500] })}>
        {t("matchApproval.error.message")}
      </Typography>
    </Stack>
  );
}