import {
  Autocomplete,
  Chip,
  TextField,
  Box,
  Typography,
  Paper,
  Stack,
} from "@mui/material";
import { validateEmail } from "../../lib/utils";
import DeleteIcon from "/delete-icon.svg";
import React, { useEffect, useState } from "react";
import theme from "../../theme";
import ProfilePhoto from "../ProfilePhoto";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Tooltip from "../../theme/Tooltip.tsx";
import helpIcon from "/help-circle-secondary.svg";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants.ts";

export interface EmailRecipient {
  firstName: string;
  lastName: string;
  profilePictureUrl?: string;
  email: string;
  isManualEntry?: boolean;
}

interface AutocompleteEmailInputProps {
  value: EmailRecipient[];
  onChange: (newValue: EmailRecipient[]) => void;
  onInputChange: (searchTerm: string) => Promise<EmailRecipient[]>;
  error?: string;
  setError: (error: string) => void;
}

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: EmailRecipient) => {
    return `${option.firstName} ${option.lastName} ${option.email}`;
  },
});

export default function AutocompleteEmailInput({
  value,
  onChange,
  onInputChange,
  error,
  setError,
}: AutocompleteEmailInputProps) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<EmailRecipient[]>([]);
  const { t } = useTranslation(Translation.sendEmailModal);

  useEffect(() => {
    const fetchData = async () => {
      if (!inputValue) {
        setOptions([]);
        return;
      }

      try {
        const results = await onInputChange(inputValue);
        setOptions(results);
      } catch (error) {
        console.error("Error fetching autocomplete results:", error);
        setOptions([]);
      }
    };

    const timeoutId = setTimeout(() => void fetchData(), 300);
    return () => clearTimeout(timeoutId);
  }, [inputValue, onInputChange]);

  const handleInputChange = (
    _event: React.SyntheticEvent,
    newInputValue: string,
  ) => {
    setInputValue(newInputValue);
    // clear error message when user starts typing
    if (setError) {
      setError("");
    }
  };

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: (EmailRecipient | string)[],
  ) => {
    // check if any of the new values are invalid emails
    if (newValue.some((item) => typeof item === "string" && !validateEmail(item))) {
        setError("Please enter a valid email address");
        // Don't create pill, keep the current value unchanged
        return;
      }

    const processedValues = newValue
      .map((item: EmailRecipient | string): EmailRecipient => {
        if (typeof item === "string") {
            return {
              firstName: "",
              lastName: "",
              email: item,
              isManualEntry: true,
            };
        }
        return item;
      })

    onChange(processedValues);
  };

  return (
    <Stack spacing="0.375rem">
      <LabelWithTooltip
        label={t("toTooltip.label")}
        tooltipText={t("toTooltip.content")}
        required={true}
      />
      <Autocomplete
        disableClearable={true}
        autoHighlight={true}
        multiple
        filterSelectedOptions
        options={options}
        inputValue={inputValue}
        value={value}
        onChange={handleChange}
        filterOptions={(options, params) => {
          const filtered = filterOptions(options, params);
          if (params.inputValue !== "" && validateEmail(params.inputValue)) {
            return filtered;
          }
          return filtered;
        }}
        onInputChange={handleInputChange}
        getOptionLabel={(option) => {
          if (typeof option === "string") return option;
          return option.isManualEntry
            ? option.email
            : `${option.firstName} ${option.lastName}`;
        }}
        isOptionEqualToValue={(option, value) => option.email === value.email}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            helperText={error}
            inputProps={{
              ...params.inputProps,
              className: "email-autocomplete-input",
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "5px 9px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: (theme) => theme.palette.grey[300],
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: (theme) => theme.palette.grey[400],
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: (theme) => `${theme.palette.primary.dark}`,
                },
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            sx={{
              padding: "8px 16px",
              "&:hover": {
                backgroundColor: theme.palette.grey[100] + " !important",
              },
              "&.Mui-focused": {
                backgroundColor: theme.palette.grey[100] + " !important",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                width: "100%",
              }}
            >
              {!option.isManualEntry && (
                <ProfilePhoto
                  firstName={option.firstName}
                  lastName={option.lastName}
                  photoUrl={option.profilePictureUrl}
                />
              )}
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0.1rem" }}
              >
                <Typography
                  fontSize="0.875rem"
                  fontWeight={500}
                  color={theme.palette.grey[600]}
                >
                  {option.isManualEntry
                    ? option.email
                    : `${option.firstName} ${option.lastName}`}
                </Typography>
                {!option.isManualEntry && (
                  <Typography
                    fontSize="0.875rem"
                    fontWeight={400}
                    color={theme.palette.grey[500]}
                  >
                    {option.email}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        )}
        renderTags={(tagValue, getTagProps) => (
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              maxWidth: "70%",
              borderRight: `1px solid ${theme.palette.grey[300]}`,
              overflowX: "auto",
              flexWrap: "nowrap",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option.email}
                variant="outlined"
                sx={{
                  borderRadius: ".5rem",
                  borderColor: theme.palette.grey[300],
                  flexShrink: 0,
                  "& .MuiChip-label": {
                    padding: "0rem",
                    marginRight: "0.1875rem",
                  },
                  "& .MuiChip-deleteIcon": {
                    margin: "0rem",
                  },
                }}
                style={{
                  color: theme.palette.grey[800],
                  height: "1.5rem",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  lineHeight: "1.25rem",
                  paddingLeft: "0.5625rem",
                  paddingRight: "0.25rem",
                  paddingTop: "0.125rem",
                  paddingBottom: "0.125rem",
                }}
                label={
                  option.isManualEntry
                    ? option.email
                    : `${option.firstName} ${option.lastName}`
                }
                deleteIcon={<img src={DeleteIcon} alt={"Delete icon"} />}
              />
            ))}
          </Box>
        )}
        PaperComponent={({ children, ...props }) => (
          <Paper
            {...props}
            sx={{
              marginTop: "4px",
              maxHeight: "320px",
              "& .MuiAutocomplete-listbox": {
                padding: 0,
              },
            }}
          >
            {children}
          </Paper>
        )}
        freeSolo
        selectOnFocus
        handleHomeEndKeys
        sx={{
          ".MuiAutocomplete-inputRoot": {
            flexWrap: "nowrap !important",
            overflowX: "auto",
          },
          "& .MuiInputBase-root": {
            height: "2.5rem",
            padding: "0.625rem 0.875rem",
            borderRadius: "0.5rem",
            backgroundColor: theme.palette.common.white,
          },
          "& .MuiOutlinedInput-root": {
            padding: "4px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.grey[300] + " !important",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.grey[400] + " !important",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.dark + " !important",
            },
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.custom.errorBorder + " !important",
            },
            "& input": {
              maxWidth: "30%",
              padding: "4px 8px !important",
              fontSize: "0.875rem",
              fontWeight: "400",
              lineHeight: "1.25rem",
            },
          },
        }}
      />
    </Stack>
  );
}

const LabelWithTooltip = ({
  label,
  tooltipText,
  required,
}: {
  label: string;
  tooltipText: string;
  required?: boolean;
}) => (
  <Stack direction="row" alignItems="center">
    <Typography className="input-label" fontSize="0.875rem" fontWeight={500}>
      {label}
    </Typography>
    {required && (
      <Typography
        fontSize="0.875rem"
        fontWeight={500}
        color="grey.500"
        lineHeight="1.25rem"
        paddingRight="0.25rem"
      >
        *
      </Typography>
    )}
    <Tooltip
      arrow={false}
      title={tooltipText}
      className="tooltip"
      placement="right-start"
      componentsProps={{
        tooltip: {
          sx: {
            padding: "0.75rem",
            display: "flex",
            flexDirection: "column",
            borderRadius: "0.5rem",
            bgcolor: "white",
            color: "grey.500",
            fontSize: "0.75rem",
            fontWeight: 500,
            boxShadow:
              "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);",
          },
        },
      }}
    >
      <Box component="img" paddingLeft="0.25rem" src={helpIcon} />
    </Tooltip>
  </Stack>
);
