import { Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import CenterModal from "../CenterModal";
import Button from "../../theme/Button";
import { NoteDTO } from "../../models/api/alum";
import NoteCard from "./NoteCard";
import leftChevronSvg from "/chevron-left.svg";
import closeSvg from "/x-close.svg";
import trashSvg from "/trash-01.svg";
import theme from "../../theme";
import { SvgButton, SvgButtonSize } from "../../theme/SvgButton.tsx";
import Box from "@mui/material/Box";
import { Format, trackGoogleAnalyticsEvent } from "../../lib/utils.ts";
import { deleteNote } from "../../api/note.ts";
import { toast } from "react-toastify";
import { toastContainerIds } from "./Notes.tsx";
import { NoteEvent } from "../../lib/eventEnums.ts";
import { useContext, useState } from "react";
import { UserContext } from "../../lib/context.ts";
import ConfirmActionModal from "../ConfirmActionModal/index.tsx";

interface ExpandedNoteModalProps {
  note: NoteDTO | null;
  onClose: () => void;
  onDelete: (id: string) => void;
  showBackButton?: boolean;
  onBack?: () => void;
}

export const ExpandedNoteModal = ({
  note,
  onClose,
  onDelete,
  showBackButton = false,
  onBack
}: ExpandedNoteModalProps) => {
  const { t, i18n } = useTranslation(Translation.alumProfile);
  const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);
  const [user, _] = useContext(UserContext);
  const orgId = user?.orgId ?? "";

  if (!note) return null;

  const handleDelete = async () => {
      if (!note) return;
  
      try {
        await deleteNote(orgId, note.id);
        
        toast.info(t("notes.toast.deleteSuccess"), {
          containerId: toastContainerIds.info,
          toastId: "delete-note-success",
        });
        trackGoogleAnalyticsEvent({
          event: NoteEvent.NOTES_DELETED,
          type: "notes_deleted",
          org: user?.orgName,
        });

        setShowDeleteNoteModal(false);
        onDelete(note.id);
      } catch (error) {
        toast.error(t("notes.toast.deleteError"), {
          containerId: toastContainerIds.error,
          toastId: "delete-note-error",
        });
      }
    };

  return (
    <>
    <CenterModal
      open={true}
      onClose={showBackButton ? onBack : onClose}
      sx={{
        width: "40rem",
        height: "31.25rem",
        py: "1.5rem",
        outline: "none",
        overflow: "auto",
      }}
    >
      <Stack id="expanded-note-modal" gap="1.5rem" height="100%">
        <Stack direction="row" alignItems="center" gap={2} px="1.5rem">
          {showBackButton && (
            <SvgButton src={leftChevronSvg} onClick={onBack} />
          )}
          <Typography
            fontWeight={400}
            fontSize="0.75rem"
            flexGrow={1}
            color={theme.palette.grey[500]}
            textAlign="center"
          >
            {t("notes.expandedView.title", {
              firstName: note.created_by.first_name,
              lastName: note.created_by.last_name,
              date: Format.dateLongDayMonthYear(note.created_date, i18n.language),
            })}
          </Typography>
          <SvgButton
            src={closeSvg}
            onClick={onClose}
            size={SvgButtonSize.MEDIUM}
          />
        </Stack>
        <Divider />
        <Box flex={1} px="1.5rem">
          <NoteCard note={note} expanded />
        </Box>
        <Divider />
        <Stack
          direction="row"
          justifyContent="flex-end"
          px="1.5rem"
          alignContent="center"
        >
          <Button
            buttonProps={{
              onClick: () => setShowDeleteNoteModal(true),
              sx: {
                padding: "0.625rem 1rem",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0.5rem",
                backgroundColor: theme.palette.background.default,
                "&:hover": {
                  backgroundColor: theme.palette.errorColor[50],
                  boxShadow: "none"
                },
              },
            }}
          >
            <Stack direction="row" gap="0.5rem">
              <Box
                component="img"
                src={trashSvg}
                sx={{
                  width: "1.25rem",
                  height: "1.25rem",
                }}
              />
              <Typography
                fontSize="0.875rem"
                fontWeight={600}
                color={theme.palette.errorColor[700]}
                lineHeight="1.25rem"
              >
                {t("notes.expandedView.discard")}
              </Typography>
            </Stack>
          </Button>
        </Stack>
      </Stack>
    </CenterModal>

    <ConfirmActionModal
        open={showDeleteNoteModal}
        onClose={() => setShowDeleteNoteModal(false)}
        primaryButtonHandler={handleDelete}
        secondaryButtonHandler={() => setShowDeleteNoteModal(false)}
        primaryButtonBgColor={theme.palette.error.main}
        primaryButtonHoverBgColor={theme.palette.error.dark}
        heading={t("notes.deleteModal.title")}
        subHeading={t("notes.deleteModal.message")}
        primaryButtonText={t("notes.deleteModal.delete")}
        secondaryButtonText={t("notes.deleteModal.cancel")}
      />
    </>
  );
};
