import { Box, SxProps, Theme } from "@mui/material";
import InitialsIcon from "../../theme/InitialsIcon";
import { useEffect, useState } from "react";

interface ProfilePhotoProps {
  photoUrl?: string | null;
  firstName: string;
  lastName: string;
  size?: string;
  fontSize?: string;
  sx?: SxProps<Theme>;
}

export default function ProfilePhoto({
  photoUrl,
  firstName,
  lastName,
  size = "2.5rem",
  fontSize = "1rem",
  sx
}: ProfilePhotoProps) {
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setImageError(false);
  }, [photoUrl]);

  if (photoUrl && !imageError) {
    return (
      <Box
        width={size}
        height={size}
        borderRadius="50%"
        overflow="hidden"
        sx={sx}
      >
        <Box
          component="img"
          alt="profile-photo"
          src={photoUrl}
          width="100%"
          height="100%"
          onError={() => setImageError(true)}
        />
      </Box>
    );
  }

  return (
    <InitialsIcon
      firstName={firstName}
      lastName={lastName}
      size={size}
      fontSize={fontSize}
    />
  );
}