import { Stack, Typography, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import CenterModal from "../CenterModal";
import Button from "../../theme/Button";
import closeSvg from "/x-close.svg";
import theme from "../../theme";
import React, { useContext, useEffect, useState } from "react";
import { SvgButton } from "../../theme/SvgButton.tsx";
import {
  Mention,
  MentionSearchEvent,
  MentionSelectEvent,
} from "primereact/mention";
import InitialsIcon from "../../theme/InitialsIcon.tsx";
import { getOrgUsers, UserSearchDTO } from "../../api/user.ts";
import { FlagsContext, UserContext } from "../../lib/context.ts";
import Input from "../../theme/Input.tsx";
import { toast } from "react-toastify";
import { toastContainerIds } from "./Notes.tsx";
import { NoteEvent } from "../../lib/eventEnums.ts";
import { createNote } from "../../api/note.ts";
import { trackGoogleAnalyticsEvent } from "../../lib/utils.ts";
import { AlumDTO, NoteDTO } from "../../models/api/alum.ts";
import ConfirmActionModal from "../ConfirmActionModal/index.tsx";

interface AddNoteModalProps {
  alum: AlumDTO;
  open: boolean;
  onClose: () => void;
  onSave: (note: NoteDTO) => Promise<void>;
  characterLimit: number;
}

const MODAL_WIDTH = 640

export const AddNoteModal = ({
  alum,
  open,
  onClose,
  onSave,
  characterLimit,
}: AddNoteModalProps) => {
  const { t } = useTranslation(Translation.alumProfile);
  const [user, _] = useContext(UserContext);
  const orgId = user?.orgId ?? "";
  const [flags] = useContext(FlagsContext);
  const [users, setUsers] = useState<UserSearchDTO[]>([]);
  const [suggestedUsers, setSuggestedUsers] = useState<UserSearchDTO[]>([]);
  const [mentionedUsers, setMentionedUsers] = useState<UserSearchDTO[]>([]);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [noteContent, setNoteContent] = useState("");

  useEffect(() => {
    if (flags?.includes("MENTIONS")) {
      getOrgUsers(orgId).then((data) => {
        setUsers(data.users);
        setSuggestedUsers(data.users);
      });
    }
  }, [flags, orgId]);

  const onSearch = async (event: MentionSearchEvent) => {
    const query = event.query;

    setSuggestedUsers(
      users.filter(
        (user) =>
          user.first_name.toLowerCase().includes(query.toLowerCase()) ||
          user.last_name.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase()),
      ),
    );
  };

  const onSelect = (e: MentionSelectEvent) => {
    setMentionedUsers([...mentionedUsers, e.suggestion]);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context!.font = "14px Roboto";

    const lines = newValue.split("\n");
    const lastLine = lines.pop() || "";
    const words = lastLine.split(" ");
    let formattedText = lines.join("\n") + (lines.length > 0 ? "\n" : "");
    let line = "";

    words.forEach((word, index) => {
      const testLine = line + word + (index < words.length - 1 ? " " : "");
      const metrics = context!.measureText(testLine);
      const testWidth = metrics.width;

      // 640px - 24px padding on each side of the input - 16px padding on each side of the input 
      if (testWidth > ( MODAL_WIDTH - 48 - 32)) { 
        formattedText += line + "\n";
        line = word + " ";
      } else {
        line = testLine;
      }
    });

    formattedText += line;

    if (formattedText.length <= characterLimit) {
      setNoteContent(formattedText);
    } else {
      setNoteContent(formattedText.slice(0, characterLimit));
    }
  };

  const handleSave = async () => {
    try {
      // we confirm that that we can find the @first_name mention in the note content for every entry in mentionedUsers
      // because the library lets us know of a new mention via the onSelect function but not of a deletion of mention
      const mentionedUsersInContent = new Set(mentionedUsers.filter((user) =>
        noteContent.includes(`@${user.first_name}`),
      ).map((user) => user.id));

      const newNote = await createNote(orgId, alum.id, noteContent, mentionedUsersInContent);

      toast.success(t("notes.toast.saveSuccess"), {
        containerId: toastContainerIds.success,
        toastId: "save-note-success",
      });
      trackGoogleAnalyticsEvent({
        event: NoteEvent.NOTES_NEW,
        type: "notes_new",
        org: user?.orgName,
      });

      setNoteContent("");
      setMentionedUsers([]);
      onSave(newNote);
    } catch (error) {
      toast.error(t("notes.toast.saveError"), {
        containerId: toastContainerIds.error,
        toastId: "save-note-error",
      });
    }
  };

  const handleClose = () => {
    if (noteContent.trim().length > 0) {
      setShowDiscardModal(true);
    } else {
      onClose();
    }
  };

  const handleDiscard = () => {
    setNoteContent("");
    setShowDiscardModal(false);
    onClose();
    toast.info(t("notes.toast.discardedMessage"), {
      containerId: toastContainerIds.info,
      toastId: "discard-note-info",
    });
  };

  return (
    <>
      <CenterModal
        open={open}
        onClose={handleClose}
        sx={{
          width: `${MODAL_WIDTH}px`, // NOTE if we change this, we need to change it in the code that adds line breaks to the note content
          py: "1.5rem",
          maxHeight: "94vh",
          outline: "none"
        }}
      >
        <Stack id="add-note-modal" gap="1rem">
          <Stack direction="row" px="1.5rem">
            <Typography fontWeight={600} fontSize="1.25rem" flexGrow={1}>
              {t("notes.addNoteModal.title")}
            </Typography>
            <SvgButton
              id="close-add-note-modal-button"
              src={closeSvg}
              onClick={handleClose}
            />
          </Stack>
          <Divider />
          <Stack gap="0.3rem" px="24px">
            {flags?.includes("MENTIONS") ? (
              <Mention
                value={noteContent}
                onChange={handleChange}
                suggestions={suggestedUsers}
                onSearch={onSearch}
                onSelect={onSelect}
                field="first_name"
                placeholder={t("notes.addNoteModal.placeholder")}
                rows={10.75}
                itemTemplate={SuggestionListItem}
                pt={{
                  root: {
                    style: {
                      width: "100%",
                    },
                  },
                  input: {
                    id: "note-mentions-input",
                    style: {
                      borderRadius: "0.5rem",
                      width: "100%",
                      resize: "none",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "400",
                      color: theme.palette.grey[800],
                      border: `solid ${theme.palette.grey[300]} 1px`,
                      // focusBorderColor: theme.palette.primary.dark
                    },
                  },
                  panel: {
                    id: "mentions-panel",
                    style: {
                      width: "300px",
                      minWidth: "fit-content",
                      borderRadius: "8px",
                    },
                  },
                  item: {
                    style: {
                      borderRadius: "8px",
                    },
                  },
                  items: {
                    style: {
                      padding: "16px 8px",
                      gap: "4px",
                      display: "flex",
                      flexDirection: "column",
                    },
                  },
                }}
              />
            ) : (
              <Input
                id="note-input"
                error={""}
                placeholder={t("notes.addNoteModal.placeholder")}
                onChange={handleChange}
                stackProps={{ paddingTop: "1.125rem" }}
                inputProps={{
                  id: "note-input-text",
                  name: "note",
                  multiline: true,
                  value: noteContent,
                  rows: 10.75,
                  sx: {
                    borderRadius: "0.5rem",
                    "& .MuiOutlinedInput-input": {
                      fontSize: "0.875rem",
                      lineHeight: "1.5rem",
                      fontWeight: "400",
                      color: "grey.800",
                    },
                    fieldset: {
                      borderColor: theme.palette.grey[300] + " !important",
                    },
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.palette.primary.dark + " !important",
                      },
                    },
                    "&.Mui-error": {
                      fieldset: {
                        borderColor:
                          theme.palette.custom.errorBorder + " !important",
                      },
                    },
                  },
                }}
              />
            )}

            <Stack width="100%" direction="row" justifyContent="flex-end">
              <Typography
                fontSize="0.875rem"
                fontWeight={400}
                color={theme.palette.grey[500]}
              >
                {t("notes.addNoteModal.charLimit", {
                  count: noteContent.length,
                  limit: characterLimit,
                })}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack
            width="100%"
            direction="row"
            justifyContent="flex-end"
            px="1.5rem"
          >
            <Button
              buttonProps={{
                onClick: handleSave,
              }}
            >
              {t("notes.addNoteModal.save")}
            </Button>
          </Stack>
        </Stack>
      </CenterModal>
      <ConfirmActionModal
        open={showDiscardModal}
        primaryButtonHandler={handleDiscard}
        secondaryButtonHandler={() => setShowDiscardModal(false)}
        primaryButtonBgColor={theme.palette.error.main}
        primaryButtonHoverBgColor={theme.palette.error.dark}
        heading={t("notes.discardModal.title")}
        subHeading={t("notes.discardModal.message")}
        primaryButtonText={t("notes.discardModal.discard")}
        secondaryButtonText={t("notes.discardModal.cancel")}
      />
    </>
  );
};

const SuggestionListItem = (suggestion: UserSearchDTO) => {
  return (
    <Stack justifyContent="start" alignItems="center" gap="8px" direction="row">
      <InitialsIcon
        firstName={suggestion.first_name}
        lastName={suggestion.last_name}
        size="40px"
        fontSize="16px"
      />
      <Stack direction="column">
        <Typography
          fontSize="14px"
          fontWeight={500}
          lineHeight="20px"
          color={theme.palette.grey[800]}
        >
          {suggestion.first_name} {suggestion.last_name}
        </Typography>

        <Typography
          fontSize="14px"
          fontWeight={400}
          lineHeight="20px"
          color={theme.palette.grey[500]}
        >
          {suggestion.email}
        </Typography>
      </Stack>
    </Stack>
  );
};
