import { Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import CenterModal from "../CenterModal";
import { NoteDTO } from "../../models/api/alum";
import closeIcon from "/x-close.svg";
import { NoteList } from "./NoteList.tsx";
import { SvgButton } from "../../theme/SvgButton.tsx";
import Box from "@mui/material/Box";
import { ExpandedNoteModal } from "./ExpandedNoteModal.tsx";
import { useState } from "react";

interface ShowAllNotesModalProps {
  open: boolean;
  onClose: () => void;
  notes: NoteDTO[];
  onDelete: (id: string) => void;
}

export const ShowAllNotesModal = ({
  open,
  onClose,
  notes,
  onDelete,
}: ShowAllNotesModalProps) => {
  const { t } = useTranslation(Translation.alumProfile);
  const [selectedNote, setSelectedNote] = useState<NoteDTO | null>(null);

  const handleDelete = (id: string) => {
    setSelectedNote(null);
    onDelete(id);
  }

  return (
    <>
      <CenterModal
        open={open}
        onClose={onClose}
        sx={{
          width: "640px",
          padding: "1.5rem 0rem 3rem",
          maxHeight: "94vh",
          outline: "none",
          overflow: "auto",
        }}
      >
        <Stack id="show-all-notes-modal" gap="1.5rem">
          <Stack direction="row" px="1.5rem">
            <Typography fontWeight={600} fontSize="1.25rem" flexGrow={1}>
              {t("notes.title")}
            </Typography>
            <SvgButton src={closeIcon} onClick={onClose} />
          </Stack>
          <Divider />
          <Box px="1.5rem">
            <NoteList notes={notes} onNoteClick={setSelectedNote} />
          </Box>
        </Stack>
      </CenterModal>

      <ExpandedNoteModal
        note={selectedNote}
        onClose={() => {
          setSelectedNote(null);
          onClose();
        }}
        showBackButton={true}
        onBack={() => setSelectedNote(null)}
        onDelete={handleDelete}
      />
    </>
  );
};
