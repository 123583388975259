import { AlumDTO, NoteDTO } from "../../models/api/alum.ts";
import Box from "@mui/material/Box";
import { Button as MuiButton, Stack, Typography } from "@mui/material";
import EmptyList from "../EmptyList";
import noteSvg from "/note.svg";
import plusSvg from "/plus.svg";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants.ts";
import { useContext, useState } from "react";
import ErrorToastContainer from "../Toastify/ErrorToastContainer.tsx";
import InfoToastContainer from "../Toastify/InfoToastContainer.tsx";
import SuccessToastContainer from "../Toastify/SuccessToastContainer.tsx";
import { ShowAllNotesModal } from "./ShowAllNotesModal.tsx";
import { AddNoteModal } from "./AddNoteModal.tsx";
import { ExpandedNoteModal } from "./ExpandedNoteModal.tsx";
import { SvgButton } from "../../theme/SvgButton.tsx";
import { NoteList } from "./NoteList.tsx";
import { UserContext } from "../../lib/context.ts";
import { trackGoogleAnalyticsEvent } from "../../lib/utils.ts";
import { NoteEvent } from "../../lib/eventEnums.ts";
import { useTableContext } from "../../components/TableView";

interface NotesProps {
  alum: AlumDTO;
  limitTo?: number;
  showAllNotesModal?: boolean;
  setShowAllNotesModal?: (value: boolean) => void;
  showBaseComponent?: boolean;
}

export const toastContainerIds = {
  success: "notes-success",
  error: "notes-error",
  info: "notes-info",
};

export default function Notes({
  alum,
  limitTo,
  showAllNotesModal: externalShowAllNotesModal,
  setShowAllNotesModal: externalSetShowAllNotesModal,
  showBaseComponent = true,
}: NotesProps) {
  const [notes, setNotes] = useState(alum.notes ?? []);
  const [selectedNote, setSelectedNote] = useState<NoteDTO | null>(null);
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);

  const {
    activeAlumni,
    setActiveAlumni,
    favoriteAlumni,
    setFavoriteAlumni,
    archivedAlumni,
    setArchivedAlumni,
    bookmarkedJobMatches,
    setBookmarkedJobMatches,
    unmatchedJobMatches,
    setUnmatchedJobMatches,
    jobMatches,
    setJobMatches,
  } = useTableContext() ?? {};

  // If the parent component doesn't need to control the ShowAllNotesModal, use internal state
  const [internalShowAllNotesModal, setInternalShowAllNotesModal] =
    useState(false);
  const showAllNotesModal =
    externalShowAllNotesModal ?? internalShowAllNotesModal;
  const setShowAllNotesModal =
    externalSetShowAllNotesModal ?? setInternalShowAllNotesModal;

  const [user, _] = useContext(UserContext);
  const { t } = useTranslation(Translation.alumProfile);

  const updateTableContext = (updatedNotes: NoteDTO[]) => {
    const updatedJobMatches = jobMatches.map((j) => {
      if (j.applicant_id === alum.id) {
        j.notes = updatedNotes;
      }
      return j;
    });
    setJobMatches(updatedJobMatches);
    const updatedBookmarkedJobMatches = bookmarkedJobMatches.map((j) => {
      if (j.applicant_id === alum.id) {
        j.notes = updatedNotes;
      }
      return j;
    });
    setBookmarkedJobMatches(updatedBookmarkedJobMatches);
    const updatedUnmatchedJobMatches = unmatchedJobMatches.map((j) => {
      if (j.applicant_id === alum.id) {
        j.notes = updatedNotes;
      }
      return j;
    });
    setUnmatchedJobMatches(updatedUnmatchedJobMatches);
    const updatedActiveAlumni = activeAlumni.map((a) => {
      if (a.id === alum.id) {
        a.notes = updatedNotes;
      }
      return a;
    });
    setActiveAlumni(updatedActiveAlumni);
    const updatedFavoriteAlumni = favoriteAlumni.map((a) => {
      if (a.id === alum.id) {
        a.notes = updatedNotes;
      }
      return a;
    });
    setFavoriteAlumni(updatedFavoriteAlumni);
    const updatedArchivedAlumni = archivedAlumni.map((a) => {
      if (a.id === alum.id) {
        a.notes = updatedNotes;
      }
      return a;
    });
    setArchivedAlumni(updatedArchivedAlumni);
  };

  const handleSavedNote = async (newNote: NoteDTO) => {
    const updatedNotes = [newNote, ...notes];
    setNotes(updatedNotes);
    alum.notes = updatedNotes;
    updateTableContext(updatedNotes);
    setShowAddNoteModal(false);
  };

  const handleDeletedNote = async (deletedId: string) => {
    const updatedNotes = notes.filter((note) => note.id !== deletedId);
    setNotes(updatedNotes);
    alum.notes = updatedNotes;
    updateTableContext(updatedNotes);
    setSelectedNote(null);
  };

  const sortedNotes = [...(notes ?? [])].sort((a, b) => {
    return (
      new Date(b.created_date).getTime() - new Date(a.created_date).getTime()
    );
  });
  const previewNotes = limitTo ? sortedNotes.slice(0, limitTo) : sortedNotes;
  const showSeeAll = limitTo !== undefined && sortedNotes.length > limitTo;

  const handleNoteClick = (note: NoteDTO) => {
    setSelectedNote(note);
    trackGoogleAnalyticsEvent({
      event: NoteEvent.NOTES_VIEW,
      type: "notes_view",
      org: user?.orgName,
    });
  };

  const handleShowSeeAll = () => {
    setShowAllNotesModal(true);
    trackGoogleAnalyticsEvent({
      event: NoteEvent.NOTES_SHOW_ALL,
      type: "notes_show_all",
      org: user?.orgName,
    });
  };

  return (
    <>
      {showBaseComponent && (
        <Box id="notes">
          <SuccessToastContainer
            containerId={toastContainerIds.success}
            style={{ width: "652px" }}
          />
          <ErrorToastContainer
            containerId={toastContainerIds.error}
            style={{ width: "652px" }}
          />
          <InfoToastContainer
            containerId={toastContainerIds.info}
            style={{ width: "652px" }}
          />
          <Stack gap="1.5rem">
            <Stack direction="row" gap="1rem">
              <Typography fontWeight={600} fontSize="1rem" flexGrow={1}>
                {t("notes.title")}
              </Typography>
              <SvgButton
                id={"add-note-button"}
                src={plusSvg}
                onClick={() => setShowAddNoteModal(true)}
              />
            </Stack>
            {notes.length === 0 ? (
              <Stack gap="1rem">
                <EmptyList
                  icon={noteSvg}
                  iconAlt={t("notes.empty.iconAlt")}
                  title={t("notes.empty.title")}
                  message={t("notes.empty.subtitle")}
                />
                <MuiButton
                  className="add-note-button"
                  sx={{
                    padding: "0.625rem 1rem",
                    fontFamily: "inherit",
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    lineHeight: "1.25rem",
                    background: theme.palette.common.white,
                    cursor: "pointer",
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: "0.5rem",
                    color: theme.palette.grey[600],
                    "&:hover": {
                      background: theme.palette.grey[50],
                    },
                    "&:active": {
                      color: theme.palette.grey[800],
                    },
                  }}
                  onClick={() => setShowAddNoteModal(true)}
                >
                  {t("notes.empty.addNote")}
                </MuiButton>
              </Stack>
            ) : (
              <NoteList
                notes={previewNotes}
                onNoteClick={handleNoteClick}
                showFinalDivider={showSeeAll}
              />
            )}
            {showSeeAll && (
              <Typography
                fontWeight={600}
                fontSize="0.875rem"
                color={theme.palette.primary.main}
                onClick={handleShowSeeAll}
                style={{ cursor: "pointer" }}
              >
                {t("notes.showAll")}
              </Typography>
            )}
          </Stack>
        </Box>
      )}

      <ShowAllNotesModal
        open={showAllNotesModal}
        notes={sortedNotes}
        onClose={() => setShowAllNotesModal(false)}
        onDelete={handleDeletedNote}
      />

      <AddNoteModal
        open={showAddNoteModal}
        alum={alum}
        onClose={() => setShowAddNoteModal(false)}
        onSave={handleSavedNote}
        characterLimit={1000}
      />

      <ExpandedNoteModal
        note={selectedNote}
        onClose={() => setSelectedNote(null)}
        onDelete={handleDeletedNote}
        showBackButton={false}
      />

    </>
  );
}
