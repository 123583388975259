import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import { AlumDTO, getEmploymentTypeDisplay } from "../../models/api/alum";
import Table, { TableColumn } from "../../components/Table";
import { Box, Stack, Typography } from "@mui/material";
import Checkbox, { CheckedState } from "../../components/Checkbox";
import Avatar, { AvatarSize } from "../../theme/Avatar";
import {
  Format,
  getReasonForDepartureString,
  MAX_SELECTED_ALUMNI,
  trackGoogleAnalyticsEvent,
} from "../../lib/utils";
import Tooltip from "../../theme/Tooltip";
import { useContext } from "react";
import { FlagsContext, UserConfigContext } from "../../lib/context";
import { useNavigate } from "react-router-dom";
import { FormattedText, FormattedTextType } from "../../theme/FormattedText";
import { Receiver } from "../../components/SendEmailModal";
import { AlumDBEvent } from "../../lib/eventEnums";
import theme from "../../theme";
import Menu from "../../components/OptionsMenu";
import ArchiveIcon from "/archive.svg";
import BriefcaseIcon from "/dark-briefcase.svg";
import HeartHandIcon from "/heart-hand.svg";
import MailIcon from "/mail.svg";
import MinusStarIcon from "/minus-star.svg";
import RestoreIcon from "/restore.svg";
import StarIcon from "/star.svg";
import { EmployeeOrgState } from "../../models/filter.ts";
import SalaryText from "../../components/SalaryText";

interface AlumniDatabaseTableProps {
  alumni: AlumDTO[];
  page: number;
  pageSize: number;
  totalRows: number;
  onPageChange: (page: number) => void;
  selectedAlumni: Map<string, Receiver>;
  setSelectedAlumni: (selectedAlumni: Map<string, Receiver>) => void;
  onAlumniSendRole: (alum: AlumDTO) => void;
  onAlumniReferralRequest: (alum: AlumDTO) => void;
  onAlumniSendMessage: (alum: AlumDTO) => void;
  onAlumniFavorite: (alum: AlumDTO) => void;
  onAlumniRemoveFavorite: (alum: AlumDTO) => void;
  onAlumniArchive: (alum: AlumDTO) => void;
  onAlumniRestore: (alum: AlumDTO) => void;
}

export default function AlumniDatabaseTable({
  alumni,
  page,
  pageSize,
  totalRows,
  onPageChange,
  selectedAlumni,
  setSelectedAlumni,
  onAlumniSendRole,
  onAlumniReferralRequest,
  onAlumniSendMessage,
  onAlumniFavorite,
  onAlumniRemoveFavorite,
  onAlumniArchive,
  onAlumniRestore,
}: AlumniDatabaseTableProps) {
  const { t } = useTranslation(Translation.alumniDatabase);
  const common = useTranslation(Translation.common)[0];
  const navigate = useNavigate();

  const [userConfig] = useContext(UserConfigContext);

  const orgName = alumni.length > 0 ? alumni[0].org_name : "";

  const someChecked = selectedAlumni.size > 0;
  const maxChecked = selectedAlumni.size === MAX_SELECTED_ALUMNI;
  const noneChecked = selectedAlumni.size === 0;

  // Check to see if we want to show last Known Role and other post-departure related fields.
  const [flags] = useContext(FlagsContext);

  const showPostDepartureDataRelatedFields = () => {
    return flags?.includes("SHOW_POST_DEPARTURE_DATA") ?? false;
  };

  const showReasonForDeparture = alumni.some(
    (alumni) => alumni.reason_for_dismissal || alumni.termination_description || alumni.termination_code
  );

  // If PDD is enabled but the current role is the same as the last role at the org,
  // and the current company is the same as the current org, we should render no text for
  // both the last role at org and the current company.
  const renderCorrectCurrentJobTitleText = (alum: AlumDTO) => {
    if (
      alum.last_job_title_at_org === alum.current_job_title &&
      alum.current_company === orgName
    ) {
      return "";
    }
    return alum.current_job_title;
  };

  const renderCorrectCurrentCompanyText = (alum: AlumDTO) => {
    if (
      alum.last_job_title_at_org === alum.current_job_title &&
      alum.current_company === orgName
    ) {
      return "";
    }
    return alum.current_company;
  };

  // the expected minimum widths of the columns in REM
  const COLUMN_WIDTHS = {
    name: 20,
    formerRole: 15,
    formerEmploymentType: 10,
    formerSalary: 10,
    lastKnownRole: 15,
    lastKnownLocation: 15,
    departureDate: 10,
    reasonForDeparture: 15,
    lastTimeContacted: 10,
    wouldRehire: 5,
    singleDropDown: 5,
  };

  const calculateMinTableWidth = () => {
    let minTableWidth = 0;
    Object.values(COLUMN_WIDTHS).forEach((width) => {
      minTableWidth += width;
    });

    if (!userConfig?.hasAlumHiringPreferencesData()) {
      minTableWidth -= COLUMN_WIDTHS.wouldRehire;
    }

    if (!userConfig?.hasSalaryData()) {
      minTableWidth -= COLUMN_WIDTHS.formerSalary;
    }

    if (!showPostDepartureDataRelatedFields()) {
      minTableWidth -= COLUMN_WIDTHS.lastKnownRole;
      minTableWidth -= COLUMN_WIDTHS.lastKnownLocation;
    }

    return minTableWidth;
  };

  const minTableWidth = calculateMinTableWidth();

  const STICKY_COLUMN_STYLES = {
    position: "sticky",
    background: "inherit",
    zIndex: 1,
  };

  const nameColumnStyle = {
    ...STICKY_COLUMN_STYLES,
    width: `calc(${COLUMN_WIDTHS.name} / (${minTableWidth}) * 100%)`,
    left: 0,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  };

  const singleDropDownColumnStyle = {
    ...STICKY_COLUMN_STYLES,
    width: `calc(${COLUMN_WIDTHS.singleDropDown} / (${minTableWidth}) * 100%)`,
    right: 0,
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    alignItems: "center",
  };

  interface MenuOption {
    className: string;
    label: string;
    handler: () => void;
    hasDividerAbove: boolean;
    icon: JSX.Element;
    disabled?: boolean;
  }

  const generateMenuOptions = (alum: AlumDTO): MenuOption[] => {
    const allOptions = [
      {
        className: "send-role-option",
        label: t("menuOptions.sendRole.label"),
        handler: () => onAlumniSendRole(alum),
        hasDividerAbove: false,
        icon: <Box component="img" src={BriefcaseIcon} />,
      },
      {
        className: "send-referral-request-option",
        label: t("menuOptions.sendReferralRequest.label"),
        handler: () => onAlumniReferralRequest(alum),
        hasDividerAbove: false,
        icon: <Box component="img" src={HeartHandIcon} />,
        disabled: !alum.is_subscribed,
      },
      {
        className: "send-message-option",
        label: t("menuOptions.sendMessage.label"),
        handler: () => onAlumniSendMessage(alum),
        hasDividerAbove: false,
        icon: <Box component="img" src={MailIcon} />,
      },
    ];

    const actionOptions = [];

    if (alum.state === EmployeeOrgState.ARCHIVE) {
      return [
        {
          className: "restore-option",
          label: t("menuOptions.restore.label"),
          hasDividerAbove: false,
          handler: () => onAlumniRestore(alum),
          icon: <Box component="img" src={RestoreIcon} />,
        },
      ];
    } else {
      if (alum.state !== EmployeeOrgState.FAVORITE) {
        actionOptions.push({
          className: "favorite-option",
          label: t("menuOptions.favorite.label"),
          handler: () => onAlumniFavorite(alum),
          hasDividerAbove: true,
          icon: <Box component="img" src={StarIcon} />,
        });
      } else {
        actionOptions.push({
          className: "remove-favorite-option",
          label: t("menuOptions.removeFavorite.label"),
          handler: () => onAlumniRemoveFavorite(alum),
          hasDividerAbove: true,
          icon: <Box component="img" src={MinusStarIcon} />,
        });
      }

      actionOptions.push({
        className: "archive-option",
        label: t("menuOptions.archive.label"),
        handler: () => onAlumniArchive(alum),
        hasDividerAbove: true,
        icon: <Box component="img" src={ArchiveIcon} />,
      });
    }

    return [...allOptions, ...actionOptions];
  };

  const columns: TableColumn<AlumDTO>[] = [
    {
      id: "name",
      label: () => (
        <Stack gap="1rem" alignItems="center" direction="row">
          <Stack height="1.5rem" justifyContent="center">
            <Tooltip title={noneChecked ? t("tooltip.cannotSelectAll") : ""}>
              <div>
                <Checkbox
                  id="checkbox"
                  checked={
                    maxChecked || someChecked
                      ? CheckedState.MIXED
                      : CheckedState.UNCHECKED
                  }
                  onCheck={onSelectAll}
                  disabled={noneChecked}
                />
              </div>
            </Tooltip>
          </Stack>

          <Typography
            fontSize="0.75rem"
            fontWeight={500}
            lineHeight="1.125rem"
            color={(theme) => theme.palette.grey[500]}
          >
            {t("table.name.label")}
          </Typography>
        </Stack>
      ),
      content: (alum: AlumDTO) => (
        <Stack gap="1rem" alignItems="center" direction="row">
          <Stack height="1.5rem" justifyContent="center">
            <Tooltip
              title={
                maxChecked && !selectedAlumni.has(alum.id)
                  ? t("tooltip.maxSelectionsReached")
                  : ""
              }
            >
              <div>
                <Checkbox
                  id="checkbox"
                  checked={
                    selectedAlumni.has(alum.id)
                      ? CheckedState.CHECKED
                      : CheckedState.UNCHECKED
                  }
                  onCheck={() => {
                    onSelectRow(alum);
                  }}
                  disabled={!selectedAlumni.has(alum.id) && maxChecked}
                />
              </div>
            </Tooltip>
          </Stack>

          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => onRowClick(alum)}
            width="100%"
          >
            <Avatar
              firstName={alum.first_name}
              lastName={alum.last_name}
              email={alum.email}
              size={AvatarSize.MEDIUM}
              numOfNotes={alum.notes?.length}
              notes={alum.notes}
              photoUrl={alum.profile_photo_url}
              isSubscribed={alum.is_subscribed}
              alumState={alum.state}
              showAlumState={alum.state !== EmployeeOrgState.ARCHIVE}
              onAvatarClick={() => onRowClick(alum)}
            />
          </Box>
        </Stack>
      ),
      styles: nameColumnStyle,
    },
    {
      id: "formerRole",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.formerRole.label", { org: orgName })}
        </Typography>
      ),
      content: (alum: AlumDTO) => {
        return (
          <Stack>
            <FormattedText
              props={{
                value: alum.last_job_title_at_org,
                format: Format.uppercaseFirstLetter,
              }}
            />
            <FormattedText
              props={{
                value: alum.last_job_department_at_org,
                format: Format.uppercaseFirstLetter,
                type: FormattedTextType.SECONDARY,
              }}
            />
          </Stack>
        );
      },
      styles: {
        width: `calc(${COLUMN_WIDTHS.formerRole} / (${minTableWidth}) * 100%)`,
      },
    },
    {
      id: "formerEmploymentType",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.formerEmploymentType.label")}
        </Typography>
      ),
      content: (alum: AlumDTO) => (
        <FormattedText
          props={{
            value: getEmploymentTypeDisplay(
              alum.former_employment_type,
              common,
            ),
          }}
        />
      ),
      styles: {
        width: `calc(${COLUMN_WIDTHS.formerEmploymentType} / (${minTableWidth}) * 100%)`,
      },
    },
    ...(userConfig?.hasSalaryData()
      ? [
          {
            id: "formerSalary",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
                align={"right"}
              >
                {t("table.formerSalary.label")}
              </Typography>
            ),
            content: (alum: AlumDTO) => {
              const payRate = alum.former_salary;
              const payPeriod = alum.former_salary_pay_period;
              return <SalaryText payRate={payRate} payPeriod={payPeriod} />;
            },
            styles: {
              width: `calc(${COLUMN_WIDTHS.formerSalary} / (${minTableWidth}) * 100%)`,
            },
          },
        ]
      : []),
    ...(showPostDepartureDataRelatedFields()
      ? [
          {
            id: "lastKnownRole",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.lastKnownRole.label")}
              </Typography>
            ),
            content: (alum: AlumDTO) => {
              const lastJobTitle = renderCorrectCurrentJobTitleText(alum);
              const lastCompany = renderCorrectCurrentCompanyText(alum);

              return (
                <Stack>
                  <FormattedText
                    props={{
                      value: lastJobTitle,
                      format: Format.uppercaseFirstLetter,
                    }}
                  />
                  <FormattedText
                    props={{
                      value: lastCompany,
                      format: Format.uppercaseFirstLetter,
                      type: FormattedTextType.SECONDARY,
                    }}
                  />
                </Stack>
              );
            },
            styles: {
              width: `calc(${COLUMN_WIDTHS.lastKnownRole} / (${minTableWidth}) * 100%)`,
            },
          },
          {
            id: "lastKnownLocation",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.lastKnownLocation.label")}
              </Typography>
            ),
            content: (alum: AlumDTO) => (
              <FormattedText
                props={{
                  value: alum.last_known_location,
                  format: Format.location,
                }}
              />
            ),
            styles: {
              width: `calc(${COLUMN_WIDTHS.lastKnownLocation} / (${minTableWidth}) * 100%)`,
            },
          },
        ]
      : []),
    {
      id: "departureDate",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
          align="right"
        >
          {t("table.departureDate.label")}
        </Typography>
      ),
      content: (alum: AlumDTO) => (
        <FormattedText
          props={{
            value: alum.end_date_at_org,
            format: Format.dateMonthYear,
          }}
          style={{
            align: "right",
          }}
        />
      ),
      styles: {
        width: `calc(${COLUMN_WIDTHS.departureDate} / (${minTableWidth}) * 100%)`,
      },
    },
    ...(showReasonForDeparture
      ? [
          {
            id: "reasonForDeparture",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.reasonForDeparture.label")}
              </Typography>
            ),
            content: (alum: AlumDTO) => {
              return (
                <FormattedText
                  props={{
                    value: getReasonForDepartureString(
                      alum.reason_for_dismissal,
                      alum.termination_description,
                      alum.termination_code
                    ),
                    format: Format.uppercaseFirstLetter,
                  }}
                />
              );
            },
            styles: {
              width: `calc(${COLUMN_WIDTHS.reasonForDeparture} / (${minTableWidth}) * 100%)`,
            },
          },
        ]
      : []),
    {
      id: "lastTimeContacted",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
          align="right"
        >
          {t("table.lastTimeContacted.label")}
        </Typography>
      ),
      content: (alum: AlumDTO) => (
        <FormattedText
          props={{
            value: alum.last_time_contacted,
            format: Format.dateDayMonthYear,
          }}
          style={{
            align: "right",
          }}
        />
      ),
      styles: {
        width: `calc(${COLUMN_WIDTHS.lastTimeContacted} / (${minTableWidth}) * 100%)`,
      },
    },
    ...(userConfig?.hasAlumHiringPreferencesData()
      ? [
          {
            id: "wouldRehire",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.wouldRehire.label")}
              </Typography>
            ),
            content: (alum: AlumDTO) => (
              <FormattedText
                props={{
                  value: alum.would_rehire
                    ? t("table.wouldRehire.yes")
                    : alum.would_rehire == null
                    ? t("table.wouldRehire.notApplicable")
                    : t("table.wouldRehire.no"),
                }}
              />
            ),
            styles: {
              width: `calc(${COLUMN_WIDTHS.wouldRehire} / (${minTableWidth}) * 100%)`,
            },
          }
        ]
      : []),
    {
      id: "singleDropDown",
      label: () => <></>,
      content: (alum: AlumDTO) => (
        <Menu
          className="row-dropdown-opener"
          items={generateMenuOptions(alum)}
        />
      ),
      styles: singleDropDownColumnStyle,
    },
  ];

  const onRowClick = (alum: AlumDTO) => {
    trackGoogleAnalyticsEvent({
      event: AlumDBEvent.ALUM_DB_ALUM_CLICK,
      org: orgName,
      customParameters: {
        name: alum.first_name + " " + alum.last_name,
        id: alum.id,
      },
    });
    navigate(alum.id);
  };

  const onSelectRow = (alum: AlumDTO) => {
    const newSelectedAlumni = new Map(selectedAlumni);
    if (newSelectedAlumni.has(alum.id)) {
      newSelectedAlumni.delete(alum.id);
    } else {
      const newReceiver: Receiver = {
        id: alum.id,
        firstName: alum.first_name,
        lastName: alum.last_name,
        email: alum.email,
        isSubscribed: alum.is_subscribed,
      };
      newSelectedAlumni.set(alum.id, newReceiver);
    }
    setSelectedAlumni(newSelectedAlumni);
  };

  const onSelectAll = () => {
    if (someChecked) {
      setSelectedAlumni(new Map());
    }
  };

  return (
    <Table
      columns={columns}
      rows={alumni}
      page={page}
      pageSize={pageSize}
      totalRows={totalRows}
      onPageChange={onPageChange}
      minWidth={minTableWidth + "rem"}
      categoryPlural={t("paginationFooter")}
      categorySingular={t("paginationFooter")}
      emptyResultText={t("emptyResult")}
      maxPagesToShow={7}
    />
  );
}
